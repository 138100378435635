module.exports = {
  // locales: ['en', 'ru', 'ca', 'es', 'it', 'el', 'fr', 'de', 'nl', 'pl', 'tk', 'hi', 'zh-cn', 'zh-hk'],
  // locales: ['en', 'de', 'es', 'it', 'fr', 'ru', 'pl'],
  locales: ['en', 'de', 'es', 'ru'],
  defaultLocale: 'en',
  pages: {
    '*': ['common', 'header', 'setup'],
    '/': ['home'],
    'rgx:/resource\\S+': ['resource_badges'],
    'rgx:/resource/\\S+': ['resource_page'],
  },
  loadLocaleFrom: (locale, namespace) =>
    import(`./locales/${locale}/${namespace}`)
      .then((m) => m.default)
      .catch(() => import(`./locales/en/${namespace}`).then((m) => m.default)),
};
