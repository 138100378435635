
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import { purple } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import Countly from 'countly-sdk-web';
import { useTheme } from 'next-themes';
import dynamic from 'next/dynamic';
import Router from 'next/router';
import NProgress from 'nprogress';
import { createContext, useEffect, useMemo, useState } from 'react';
import { hit } from 'lyam';
import '../App.css';
import '../assets/css/Header.css';
import '../assets/css/LoginModal.scss';
import '../index.css';
import '../themes.css';
import initAnalytics from '../util/initAnalytics.ts';
import './home.scss';

const CApp = dynamic(() => import('../components/App'));

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', (url) => {
  NProgress.done();
  // To hit only in production and only on client side (in browser)
  if (typeof window !== 'undefined' && process.env.NODE_ENV === 'production') {
    hit(process?.env?.NEXT_PUBLIC_YM_ID, { url });
    Countly.q.push(['track_pageview', url]);
  }
  if (url.includes('/feedback')) import('../assets/css/feedbackForms.scss');
});
Router.events.on('routeChangeError', () => NProgress.done());

const PrefContext = createContext();

export { PrefContext };
function MyApp({ Component, pageProps }) {
  const [preferences, setPreferences] = useState({});
  // const [locale, setLocale] = useState(null)
  const [themeName, setThemeName] = useState('');
  const [actualTheme, setActualTheme] = useState();
  const [loaded, setLoaded] = useState(false);
  const [showSetup, setShowSetup] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const { setTheme } = useTheme();

  const selectTheme = (_theme) => {
    if (!_theme) return;
    // console.log('Set theme', _theme);
    const newTheme = _theme === 'auto' ? 'system' : _theme;
    setTheme(newTheme);
    setThemeName(newTheme);
    document.getElementsByTagName('html')[0].setAttribute('data-theme', newTheme);
    window.localStorage.setItem('theme', newTheme);
    if (newTheme === 'system') {
      const mqv = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
      setActualTheme(mqv);
      document.getElementsByTagName('html')[0].setAttribute('data-theme', mqv);
    } else setActualTheme(newTheme);
  };

  useEffect(() => {
    const lsTheme = window.localStorage.getItem('theme');
    selectTheme(lsTheme || 'system');
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
      if (lsTheme !== 'system') return;
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        selectTheme('dark');
      } else {
        selectTheme('light');
      }
    });
    const prefs = JSON.parse(window.localStorage.getItem('preferences')) || { currency: 'USD' };
    setPreferences(prefs);
    setLoaded(true);
    document.addEventListener(
      'keydown',
      (e) => {
        if (e?.ctrlKey && (e?.key === 's' || e?.key === 'k')) {
          // console.log('prevent');
          e.preventDefault();
        }
      },
      false
    );

    import('../assets/css/nprogress.css');
    if (!window.localStorage.getItem('setupDone')) setTimeout(() => setShowSetup(true), 700);
    if (typeof window === 'undefined' || process.env.NODE_ENV !== 'production') return;
    window.Countly = Countly;
    initAnalytics(Countly);
    // eslint-disable-next-line no-unsafe-optional-chaining
    const url = window?.location?.pathname + window?.location?.search || null;
    hit(process?.env?.NEXT_PUBLIC_YM_ID, { url });
    console.log(`%cIf you don't know what are you doing close dev tools.`, `font-size: 3rem; padding: 0.5rem; background: linear-gradient(#ff0000, #ff00cc);`);
    if (process?.env?.BRANCH !== 'master' && process?.env?.BRANCH !== 'HEAD') console.log(`%c Running preview for ${process.env.BRANCH} branch !`, `font-size: 2rem; padding: 0.25rem; color: hotpink; background: linear-gradient(#84fab0, #8fd3f4);`);
    console.log({ version: process.env.VERSION, hash: process.env.git_hash.slice(0, 7), commit: process.env.git_hash, branch: process.env.BRANCH });
  }, []);

  useEffect(() => {
    window.localStorage.setItem('preferences', JSON.stringify(preferences));
  }, [preferences]);

  // if (!loaded) return <h1>Loading</h1>

  const MuiTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: actualTheme || 'dark',
          background: {
            // paper: purple[500],
          },
          primary: {
            // Purple and green play nicely together.
            main: purple[500],
          },
          secondary: {
            // This is green.A700 as hex.
            main: '#11cb5f',
          },
        },
      }),
    [actualTheme, themeName, loaded]
  );

  return (
    <CApp
      PrefContext={PrefContext}
      preferences={preferences}
      setPreferences={setPreferences}
      MuiTheme={MuiTheme}
      setShowSearchModal={setShowSearchModal}
      showSearchModal={showSearchModal}
      selectTheme={selectTheme}
      themeName={themeName}
      showSetup={showSetup}
      setShowSetup={setShowSetup}
      Component={Component}
      pageProps={pageProps}
      loaded={loaded}
    />
  );
}

const __Page_Next_Translate__ = MyApp;


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      
    });
  